* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-weight: 300;
    font-style: normal;
    letter-spacing: 0.03em;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #fffefc;
}

body {
    background-color: black;
    font-family: 'Raleway', sans-serif;
}

a {
    text-decoration: none;
}

h1,
h2,
h3 {
    text-align: center;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
}

h1 {
    font-size: 40px;
    line-height: 60px;
    font-weight: 600;
    color: #c4c4c4;
}

h2 {
    font-size: 25px;
    line-height: 38px;
    font-weight: 600;
    color: #fff;
}

h3 {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 28px;
    font-weight: 400;
    color: #9fa49e;
    min-height: max-content;
}

@media (max-width: 768px) {
    h1 {
        font-size: 30px;
        line-height: 45px;
    }

    h2 {
        font-size: 22px;
        line-height: 33px;
    }

    h3 {
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 18px;
    }
}

strong {
    font-weight: 700;
}

// https://stackoverflow.com/questions/2460100/remove-the-complete-styling-of-an-html-button-submit
button,
input[type='submit'],
input[type='reset'] {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

button {
    padding: 10px 15px;
    border-radius: 30px;
    cursor: pointer;
    background: transparent;
    transition: 0.25s ease all;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 150px;
    color: $white-shade-2;
    border: 1px solid $white-shade-2;

    @media (max-width: 768px) {
        min-width: 100px;
        font-size: 14px;
        list-height: 14px;
    }

    svg {
        max-width: 19px;
        max-height: 19px;

        @media (max-width: 768px) {
            max-width: 14px;
            max-height: 14px;
        }
    }

    g {
        stroke: $white-shade-1;
        transition: 0.25s ease all;
    }

    &:hover {
        background-color: $dark-shade-1;
        color: $white-shade-2;
        border: 1px solid transparent;

        g {
            stroke: $white-shade-2;
        }
    }

    &:active {
        transform: scale(0.92);
    }
}

::-moz-selection {
    color: black;
    background: $white-shade-4;
}

::selection {
    color: black;
    background: $white-shade-4;
}

.react-p5 {
    display: none;
}

.socialBtn {
    fill: rgba(255, 255, 255, 0.8);
    transition: 0.25s ease all;
    cursor: pointer;

    &:hover {
        fill: rgba(255, 255, 255, 1);
    }

    &:active {
        transform: scale(0.92);
    }
}
