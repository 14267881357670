.connectContainer {
    height: 100%;
    // margin-top: 100px;
    // border: 1px solid red;
    min-height: 500px;
    width: 850px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    // @media (max-width: 1440px) {
    //     margin-top: 0 !important;
    // }

    @media (max-width: 900px) {
        max-width: 90vw;
    }

    .row {
        width: 100%;
        min-height: 50px;
        // border: 1px solid green;

        &:first-child {
            height: max-content;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            border-radius: 13px;
            margin-bottom: 20px;
            padding: 25px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                max-width: 100%;
                max-height: 100%;
                border-radius: 8px;
            }
        }

        &:nth-child(2) {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 15px;

            @media (max-width: 900px) {
                justify-content: center;
            }

            span {
                font-size: 25px;
                line-height: 25px;
                font-weight: 500;
            }
        }

        &:nth-child(3) {
            margin-top: 10px;

            span {
                font-size: 40px;
                line-height: 60px;
                font-weight: 600;
                text-transform: capitalize;
            }

            @media (max-width: 900px) {
                text-align: center;

                span {
                    font-size: 30px;
                    line-height: 45px;
                    font-weight: 600;
                    text-transform: capitalize;

                    @media (max-width: 768.5px) {
                        font-size: 24px;
                        line-height: 36px;
                    }
                }
            }
        }

        &:nth-child(4) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            min-height: 120px;
            margin-top: 70px;

            @media (max-width: 900px) {
                flex-direction: column;

                span {
                    text-align: center !important;
                }
            }

            .left {
                // border-right: 1px solid #9d9d9d;
                width: 260px !important;
            }

            .line {
                width: 1px;
                height: 120px;
                background-color: #9d9d9d;

                @media (max-width: 900px) {
                    width: 250px;
                    height: 1px;
                    margin: 40px 0;
                }
            }

            .left,
            .right {
                // flex: 1;
                width: max-content;
                height: 120px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
                padding: 7px 0;

                @media (max-width: 900px) {
                    justify-content: center;
                    align-items: center;
                    height: max-content;
                    gap: 30px;
                }
            }
        }

        &:nth-child(5) {
            margin-top: 80px;
            display: flex;
            flex-direction: column;
            gap: 20px;

            span {
                width: 340px;
            }

            .buttonContainer {
                width: max-content;
                min-height: max-content;
                // border: 1px solid red;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                }
            }

            @media (max-width: 900px) {
                margin-top: 40px;
                justify-content: center;
                align-items: center;

                span {
                    width: max-content;
                }
            }
        }
    }

    @media (max-width: 768.5px) {
        margin-top: 30px;
    }

    .buttons {
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;

        svg {
            cursor: pointer;
        }
    }
}
