.verificationContainer {
    // border: 1px solid red;
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start !important;

    .pageContent {
        margin-top: 120px;
        width: max-content;
        margin-left: auto;
        margin-right: auto;

        @media (max-width: 768.5px) {
            margin-top: 30px;
        }
    }
}
