.headerContainer {
    min-height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #000;

    @media (max-width: 768px) {
        min-height: 70px;
    }

    .headerContent {
        width: 100%;
        height: 100%;
        max-width: 1440px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: 0.25s ease all;

        @media (max-width: 1460px) {
            padding: 0 30px;
        }

        @media (max-width: 768px) {
            padding: 0 15px;
        }

        .brandingContainer {
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 45px;
                height: 35px;
                margin-right: 7px;

                @media (max-width: 768px) {
                    width: 30px;
                    height: 23.333333333px;
                    margin-right: 4px;
                }
            }

            span {
                font-size: 24px;
                line-height: 24px;
                font-weight: 600;

                @media (max-width: 768px) {
                    font-size: 16px;
                    line-height: 16px;
                }
            }
        }

        .stateContainer {
            display: flex;
            align-items: center;
            justify-content: center;

            .connectionDetails {
                margin-right: 30px;

                @media (max-width: 768px) {
                    margin-right: 10px;
                }

                .walletId {
                    color: #ffffff88;

                    @media (max-width: 768px) {
                        font-size: 12px;
                        line-height: 12px;
                    }
                }
            }
        }
    }
}
