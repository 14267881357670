.modalBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

    .walletConnectionModalContainer {
        width: max-content;
        height: max-content;
        padding: 25px;
        border-radius: 13px;
        border: 1px solid $white-shade-2;
        background-color: #000;

        button {
            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }
    }

    .nftLabelModalContainer,
    .confirmationModal {
        width: 500px;
        height: max-content;
        padding: 25px;
        border-radius: 13px;
        border: 1px solid $white-shade-2;
        background-color: #000;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        h3 {
            margin: 0 auto 0 0;
            font-size: 24px;
            line-height: 24px;
            color: #fff;
            font-weight: 400;
        }

        h4 {
            margin: 15px auto 15px 0;
            font-size: 16px;
            line-height: 16px;
            font-weight: 400;
        }

        .inputWarning {
            border: 1px solid #cc3300 !important;
            color: #cc3300 !important;
        }

        input {
            background-color: transparent;
            border: 1px solid $white-shade-2;
            outline: none;
            margin: 0 auto 30px 0;
            height: 40px;
            color: $white-shade-1;
            padding: 10px;
            border-radius: 8px;
            transition: 0.25s ease all;

            &:active,
            &:focus,
            &:hover {
                border: 1px solid $white-shade-1;
            }
        }

        .buttonsContainer {
            margin: 15px 0 0 auto;
            display: flex;
            gap: 15px;
            button {
                margin: 0;
            }
        }
    }

    .qrModalContainer {
        background-color: #000;
        border-radius: 13px;
        border: 1px solid $white-shade-2;
        height: max-content;
        width: 550px;
        padding: 25px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        gap: 25px;

        @media (max-width: 768px) {
            height: max-content;
            width: 315px;
            padding: 25px;
        }

        .qrCode {
            width: 100%;
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
