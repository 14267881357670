#root {
    height: 100vh;

    div[data-rk] {
        height: 100vh;
    }

    .layoutContainer {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .mobileWarning {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow-x: hidden;
            position: relative;

            .bgGradient {
                width: 100vw;
                height: 100vh;
                max-width: 1440px;
                max-height: 1024px;
                position: absolute;
                z-index: -1;

                img {
                    height: 100%;
                    width: 100%;
                }
            }

            h1 {
                font-weight: 600;
                text-align: center;
                margin: 0 50px;
                max-width: 500px;

                @media (max-width: 500px) {
                    font-size: 24px;
                    line-height: 28px;
                }
            }
        }

        .userWarningContainer {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            overflow: hidden;

            .bgGradient {
                width: 100vw;
                height: 71.1vw;
                max-width: 1440px;
                max-height: 1024px;

                position: absolute;
                z-index: -1;

                img {
                    height: 100%;
                    width: 100%;
                }
            }

            .brandingContainer {
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 45px;
                    height: 35px;
                    margin-right: 7px;
                }

                span {
                    font-weight: 700;
                    font-size: 24px;
                    color: #fff;
                }

                @media (max-width: 900px) {
                    img {
                        width: 30px;
                        height: 23.333333333px;
                        margin-right: 4px;
                    }

                    span {
                        font-weight: 700;
                        font-size: 18px;
                        color: #fff;
                    }
                }
            }

            h1 {
                font-weight: 700;
                font-size: 55px;
                line-height: 83px;
                color: #fff;

                span {
                    font-weight: 700;
                }

                @media (max-width: 900px) {
                    font-size: 30px;
                    line-height: 45px;
                }
            }

            h4 {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                height: 24px;
                color: #fff;
                margin-top: -7px;
                margin-bottom: 20px;
                text-align: center;

                @media (max-width: 900px) {
                    font-size: 14px;
                    line-height: 21px;
                    max-width: 275px;
                    height: max-content;
                }
            }

            .mw-300 {
                max-width: 300px;
                text-align: center;
                height: max-content;
            }
        }

        .bgGradientContainer {
            width: 100vw;
            height: 100vh;
            position: absolute;
            transform: translate(-50%, -50%);
            left: 50%;
            top: 50%;
            z-index: -10;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 150%;
                height: 150%;
            }
        }

        main {
            overflow-y: auto;
            width: 100%;
            flex: 1;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;

            section {
                max-width: 1440px;
                width: 100%;
                flex: 1;
            }
        }

        .indexPage {
            min-height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 100px;
        }

        .processSteps {
            min-width: max-content;

            .step {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .statusContainer {
                    width: 20px;
                    height: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 5px;

                    * {
                        max-height: 100%;
                        max-width: 100%;
                    }
                }
            }
        }
    }
}
