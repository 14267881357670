.dappUIPageWrapper {
    height: max-content;
    display: flex;
    flex-direction: column;
    // flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 38px;
    margin-bottom: 100px;
    padding-top: 50px;

    .loginForm {
        background-color: rgba(0, 0, 0, 0.4);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 25px;
        gap: 15px;
        margin-top: 50px;
        border-radius: 13px;
        border: 1px solid rgba(255, 255, 255, 0.1);

        input {
            background-color: transparent;
            outline: none;
            border: 1px solid rgba(255, 255, 255, 0.1);
            width: 250px;
            height: 40px;
            border-radius: 9px;
            padding: 0 15px;
        }
    }

    .event {
        background: rgba(0, 0, 0, 0.39);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        max-width: 700px;
        min-width: 700px;
        max-height: 150px;
        min-height: 150px;
        transition: 0.25s ease all;
        border-radius: 18px;
        overflow: hidden;
        border: 1px solid #ffffff1a;

        .accordion {
            &Top {
                height: 150px;
                display: flex;
                cursor: pointer;

                .left {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;

                    .infoRow {
                        width: 100%;
                        padding: 0 25px;

                        .label {
                            font-size: 11px;
                            line-height: 11px;
                            color: rgba(255, 255, 255, 0.4);
                            margin-bottom: 2px;
                        }

                        .info {
                            font-size: 16px;
                            line-height: 16px;
                            color: rgba(255, 255, 255, 1);
                            font-weight: 500;
                        }
                    }
                }

                .right {
                    max-width: 700px;
                    min-width: 100px;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    gap: 15px;
                    position: relative;

                    .line {
                        width: 1px;
                        height: 80%;
                        position: absolute;
                        left: 0px;
                        background-color: rgba(255, 255, 255, 0.2);
                    }

                    svg {
                        width: 25%;
                        height: 25%;
                    }
                }
            }

            &Bottom {
                height: 350px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .bottomRow {
                    width: 100%;
                    height: 60px;
                    min-height: 60px;
                    transition: 0.25s ease all;
                    overflow: hidden;
                    padding: 0 25px;

                    &:not(:last-child) {
                        .top {
                            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
                        }
                    }

                    // &:last-child {
                    //     // border-bottom: 1px solid rgba(255, 255, 255, 0.2);
                    // }

                    .top {
                        height: 60px;
                        cursor: pointer;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .right {
                            svg {
                                width: 25px;
                                height: 25px;
                                transform: rotate(180deg);
                                transition: 0.25s ease all;
                            }
                        }
                    }

                    .bottom {
                        height: 170px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 7.5px 0;
                        position: relative;

                        p {
                            font-size: 13px;
                            line-height: 17px;
                        }

                        .btnContainer {
                            position: absolute;
                            width: 30px;
                            height: 30px;
                            // border: 1px solid red;
                            right: 15px;
                            top: 15px;

                            svg {
                                width: 100%;
                                height: 100%;
                                cursor: pointer;
                            }
                        }

                        .userListContainer {
                            height: 100%;
                            width: 100%;
                            border-radius: 15px;
                            padding: 15px;
                            display: flex;
                            flex-wrap: wrap;
                            gap: 10px;
                            overflow-y: auto;
                            padding-right: 30px;

                            .toDelete {
                                text-decoration: line-through !important;
                            }

                            span {
                                border: 1px solid rgba(255, 255, 255, 0.1);
                                border-radius: 8px;
                                max-height: 40px;
                                padding: 8px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                position: relative;

                                .deleteBtn {
                                    position: absolute;
                                    top: -5px;
                                    right: -5px;
                                    height: 15px;
                                    width: 15px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    cursor: pointer;

                                    svg {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }
                            }
                        }

                        .addNewUserContainer {
                            height: 100%;
                            width: 100%;
                            padding: 15px 0g;
                            display: flex;
                            flex-direction: column;
                            // border: 1px solid red;

                            .topRow {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;

                                .invalid {
                                    border: 1px solid rgba(204, 0, 0, 0.2) !important;

                                    .addBtn {
                                        .line {
                                            background-color: rgba(204, 0, 0, 0.2) !important;
                                        }

                                        svg {
                                            fill: #cc0000 !important;
                                        }
                                    }
                                }

                                .errMsg {
                                    font-size: 13px;
                                    line-height: 17px;
                                    color: #fafafa;
                                    width: 150px;
                                    height: max-content;
                                    text-align: center;
                                    margin: 0 15px;
                                    justify-self: flex-start;
                                }

                                .inputContainer {
                                    width: 400px;
                                    min-height: 40px;
                                    height: 40px;
                                    border: 1px solid rgba(255, 255, 255, 0.2);
                                    border-radius: 8px;
                                    display: flex;
                                    margin: 15px auto 15px 0;
                                    overflow: hidden;

                                    input {
                                        flex: 1;
                                        background-color: transparent;
                                        border: none;
                                        outline: none;
                                        padding: 0 7.5px;
                                        transition: 0.25s ease all;
                                    }

                                    .addBtn {
                                        min-width: 40px !important;
                                        min-height: 40px !important;
                                        border: none !important;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        position: relative;

                                        &:hover {
                                            background-color: transparent !important;
                                        }

                                        .line {
                                            width: 1px;
                                            height: 60%;
                                            position: absolute;
                                            top: 50%;
                                            left: 0px;
                                            background-color: rgba(255, 255, 255, 0.2);
                                            transform: translateY(-50%);
                                            transition: 0.25s ease all;
                                        }

                                        svg {
                                            width: 20px;
                                            height: 20px;
                                            transition: 0.25s ease all;
                                        }
                                    }
                                }

                                .approveBtn {
                                    width: 30px;
                                    height: 30px;
                                    cursor: pointer;

                                    svg {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }
                            }

                            .stagedUsers {
                                // border: 1px solid rgba(255, 255, 255, 0.1);
                                border-radius: 10px;
                                display: flex;
                                flex-wrap: wrap;
                                justify-content: flex-start;
                                align-items: flex-start;
                                gap: 10px;
                                max-height: max-content;
                                overflow-y: auto;
                                overflow-x: hidden;
                                position: relative;
                                padding: 10px 0;

                                span {
                                    border: 1px solid rgba(255, 255, 255, 0.1);
                                    border-radius: 8px;
                                    max-height: 40px;
                                    padding: 8px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    position: relative;

                                    &:hover {
                                        .deleteBtn {
                                            display: flex !important;
                                        }
                                    }

                                    .deleteBtn {
                                        position: absolute;
                                        top: -5px;
                                        right: -5px;
                                        height: 15px;
                                        width: 15px;
                                        display: none;
                                        justify-content: center;
                                        align-items: center;
                                        cursor: pointer;

                                        svg {
                                            width: 100%;
                                            height: 100%;
                                        }
                                    }
                                }
                            }
                        }

                        .linksContainer {
                            // border: 1px solid red;
                            height: 100%;
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            align-items: flex-start;
                            gap: 15px;

                            .row {
                                // border: 1px solid red;
                                width: 100%;
                                min-height: 50px;
                                border-radius: 8px;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                background-color: rgba(255, 255, 255, 0.1);
                                padding: 0 10px;

                                a {
                                    font-size: 11px;
                                    line-height: 11px;
                                    margin: 0 15px;
                                }

                                .copyBtn {
                                    font-size: 14px;
                                    line-height: 14px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    gap: 7px;
                                    border: 1px solid rgba(255, 255, 255, 0.1);
                                    padding: 8px;
                                    border-radius: 13px;
                                    cursor: pointer;
                                    transition: 0.25s ease all;

                                    &:hover {
                                        background-color: #000;
                                        border: 1px solid #000;
                                    }

                                    &:active {
                                        transform: scale(0.92);
                                    }
                                }

                                svg {
                                    width: 20px;
                                    height: 20px;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }

                .activeRow {
                    height: 100%;

                    .top {
                        border-bottom: 1px solid rgba(255, 255, 255, 0.2);

                        .right {
                            svg {
                                transform: rotate(0);
                            }
                        }
                    }
                }
            }
        }
    }

    .activeEvent {
        min-height: 500px !important;
    }
}
