::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}

::-webkit-scrollbar-thumb {
    background: $white-shade-2;
    border: 0px none $white-shade-2;
}

::-webkit-scrollbar-thumb:hover {
    background: $white-shade-2;
}

::-webkit-scrollbar-thumb:active {
    background: $white-shade-1;
}

::-webkit-scrollbar-track {
    background: transparent;
    border: 0px none $white-shade-2;
}

::-webkit-scrollbar-track:hover {
    background: transparent;
}

::-webkit-scrollbar-track:active {
    background: transparent;
}

::-webkit-scrollbar-corner {
    background: transparent;
}
