.eventPageContainer {
    width: max-content;
    margin: 50px auto 140px auto;

    @media (max-width: 1440.5px) {
        margin: 30px auto 100px auto;
    }

    @media (max-width: 768.5px) {
        margin: 20px auto 80px auto;
    }

    .retryBtn {
        margin-left: auto;
        margin-right: auto;
    }
}
