.mintContainer {
    margin-top: 120px;
    width: 350px;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    @media (max-width: 768.5px) {
        margin-top: 30px;
    }

    h1 {
        position: relative;
        transform: translateX(-50%);
        left: 50%;
    }

    h2 {
        max-width: 400px;
        font-weight: 400;
        margin: 0 auto;
        position: relative;
        transform: translateX(-50%);
        left: 50%;
    }

    h3 {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        line-height: 24px;
        font-weight: 500;
        color: #fff;
        margin-top: -5px;
    }

    img {
        margin-left: auto;
        margin-right: auto;
        display: block;
    }

    button {
        margin: 30px auto;
    }

    .declinedContainer {
        width: max-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        position: relative;
        transform: translateX(-50%);
        left: 50%;
        gap: 5px;

        h1,
        h2,
        h3 {
            position: static;
            left: none;
            transform: translateX(0);
        }

        h2 {
            margin: 10px 0;
        }

        h3 {
            max-width: 350px;
            line-height: 32px;
        }
    }

    .googleAccContainer {
        // border: 1px solid red;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;
        margin-top: 150px;
        margin-bottom: 40px;

        button {
            margin-top: 0 !important;
        }

        .logoutText {
            cursor: pointer;
            text-decoration: underline;
        }
    }
}
