.greenGradient {
    background: linear-gradient(90deg, #3dfec4 0%, #bbf32e 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.blueGradient {
    background: linear-gradient(90deg, #2ff5ca 0%, #6a44d7 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}
