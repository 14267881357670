.ticketContainer {
    margin: 120px auto;
    width: max-content;

    @media (max-width: 768px) {
        margin: 40px auto !important;
    }

    h1 {
        font-size: 36px;
        line-height: 48px;
        max-width: 600px;
        color: #fff;
        text-transform: capitalize;

        @media (max-width: 768px) {
            font-size: 24px;
            line-height: 24px;
            max-width: 90vw;
        }
    }

    h2 {
        font-size: 24px;
        line-height: 36px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .nftContainer {
        width: 400px;
        height: 400px;
        border: 1px solid #ffffff80;
        margin: 50px auto;
        border-radius: 18px;
        overflow: hidden;

        @media (max-width: 768px) {
            width: 300px;
            height: 300px;
        }

        img {
            max-width: 100%;
        }
    }

    button {
        margin: 0 auto;
    }
}
